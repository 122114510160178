.c-hamburguer {
    display: none;
    position: absolute;
    right: 90px;
    top: 20px;
    z-index: 4;
    .c-hamburguer__hero {
        @include transforms($property: rotate(0deg));
        @include transition();
        cursor: pointer;
        display: block;
        height: 26px;
        position: relative;
        width: 40px;
        .bar {
            @extend .u-showElement;
            @include roundedAll(1px);
            @include transforms($property: rotate(0deg));
            @include transition();
            background: $mutedGray;
            display: block;
            height: 3px;
            position: absolute;
            width: 100%;
            &.bar_1 {
                top: 0;
                left: 0;
            }
            &.bar_2 {
                top: 12px;
                left: 0;
            }
            &.bar_3 {
                top: 24px;
                left: 0;
            }
        }
    }
    &.is-active {
        background: $primaryLight;
        @include roundedAll(20px);
        right: -205px;
        top: -10px;
        .c-hamburguer__hero {
            @include transforms($property: rotate(180deg) scale(0.5));
            .bar {
                &.bar_1 {
                    top: 12px;
                    @include transforms($property: rotate(45deg));
                }
                &.bar_2 {
                    @extend .u-hideElement;
                }
                &.bar_3 {
                    top: 12px;
                    @include transforms($property: rotate(-45deg));
                }
            }
        }
    }
}

.c-inputBox {
    position: relative;
    display: block;
    width: 150px;
    input {
        @include roundedAll(10px);
        display: block;
        font-size: 18px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 100%;
        padding: 0 44px;
    }
    span {
        @include roundedAll(10px);
        background-color: transparent;
        color: $primary;
        cursor: pointer;
        display: block;
        font-size: 22px;
        height: 40px;
        line-height: 40px;
        position: absolute;
        text-align: center;
        width: 40px;
        font-weight: $fwSemiBold;
        &:hover {
            background: $mutedGray;
        }
        &.addUnidade {
            right: 0;
            top: 0;
        }
        &.retirarUnidade {
            left: 0;
            top: 0;
        }
    }
}

.c-orderMenu {
    // link p carrinho
    @include roundedAll(50%);
    @include transforms(scale(1), center);
    @include transition($duration: 100ms);
    background: $branco;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 60px;
    position: absolute;
    right: 0;
    top: 5px;
    width: 60px;
    z-index: 11;
    &:hover {
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        @include transforms(scale(1.05), center);
    }
    .numItens {
        @include roundedAll(50%);
        background: $primaryDark;
        color: $branco;
        display: block;
        display: block;
        font-size: 12px;
        font-weight: $fwSemiBold;
        height: 22px;
        line-height: 22px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 22px;
    }
    i {
        width: 26px;
        height: 24px;
        display: block;
    }
}

.c-btn {
    display: block;
    width: 100%;
    text-align: center;
    @include roundedAll(5px);
    border: 2px solid $branco;
    height: 60px;
    line-height: 56px;
    text-transform: uppercase;
    @include transition();
    color: $branco;
    font-size: 14px;
    font-weight: $fwSemiBold;
    &:hover {
        background: $branco;
        color: $primary;
    }
}

.c-cardProduto {
    @include roundedAll(10px);
    @include transition();
    background: $branco;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    figure {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        height: 300px;
        position: relative;
        border-bottom: 1px solid $mutedGray;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .cardInfos {
        display: block;
        padding: 25px;
        h3 {
            display: block;
            color: $text;
            font-size: 22px;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $mutedGray;
            font-weight: $fwMedium;
        }
        .moreInformation {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            margin-top: auto;
            i {
                flex: 1 1 19px;
                max-width: 20px;
                height: 20px;
                svg {
                    display: block;
                    height: 20px;
                    width: 20px;
                }
            }
            span {
                color: $primary;
                display: block;
                flex: 1 1 auto;
                font-size: 14px;
                font-weight: $fwSemiBold;
                text-transform: uppercase;
                padding-left: 10px;
            }
        }
    }
    &:hover {
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
        .cardInfos {
            h3 {
                color: $primary;
            }
        }
    }
}

.c-pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: 20px;
    margin-top: 30px;
    .prevLink, .nextLink, .numLinks {
        display: block;
    }
    .prevLink, .nextLink {
        flex: 1 1 82px;
        max-width: 82px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-weight: $fwMedium;
        color: $text;
        text-transform: uppercase;
        &.is-disabled {
            color: $mediumGray;
            &:hover {
                text-decoration: none;
                color: $mediumGray;
            }
        }
        &:hover {
            text-decoration: underline;
            color: $primary;
        }
    }
    .prevLink {
        align-self: flex-start;
    }
    .nextLink {
        align-self: flex-end;
        text-align: right;
    }
    .numLinks {
        flex: 1 1 auto;
        align-self: center;
        text-align: center;
        a {
            display: inline-block;
            width: 36px;
            height: 32px;
            line-height: 32px;
            @include roundedAll(4px);
            @include transition();
            background-color: transparent;
            color: $text;
            font-weight: $fwMedium;
            &:hover {
                background: $mutedGray;
            }
            &.is-active {
                background: $primaryDark;
                color: $branco;
            }
        }
    }
}

.c-tabelaNutricional {
    display: block;
    width: 100%;
    padding: 10px 0;
    .tb_row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        border: 1px solid $lightGray;
        background: transparent;
        @include transition();
        &:hover {
            background: $mutedGray;
        }
        &.tb_header {
            .tb_cell {
                font-weight: $fwSemiBold;
            }
        }
        .tb_cell {
            @include paragrafos();
            margin: 0;
            padding: 5px;
            border-right: 1px solid $lightGray;
            font-size: 14px;
            text-align: center;
            &:last-of-type {
                border-right: 0;
            }
            &.u-small {
                font-size: 12px;
            }
            &--tl {
                text-align: left;
            }
            &--1 {
                flex: 1 1 33.3%;
                max-width: 33.3%;
            }
            &--2 {
                flex: 1 1 66.6%;
                max-width: 66.6%;
            }
            &--3 {
                flex: 1 1 100%;
                max-width: 100%;
            }
        }
    }
}