// header home
@media screen and (max-width: 1300px) {
 	.l-headerHome {
 		.u-container {
 			max-width: 100%;
 			padding: 0 70px;
 		}
 	}
}
@media screen and (max-width: 900px) {
	.l-headerHome {
		.u-flexContainer {
			height: 720px;
			max-width: 100%;
		}
		.head_description {
 			padding-left: 0;
 		}
 		h2 {
 			width: 100%;
 			display: block;
 			text-align: center;
 			font-size: 120px;
 		}
 		p {
 			text-align: center;
 			display: block;
 			margin: 0 auto;
 			max-width: 700px;
 		}
 		a {
 			margin: 30px auto 0 auto;
 		}
	}
}
@media screen and (max-width: 520px) {
	.l-headerHome {
		.swiper-pagination {
			left: 10px !important;
			.swiper-pagination-bullet {
				width: 4px;
			}
		}
		.u-flexContainer {
			min-height: 590px;
			height: 590px;
		}
		.u-container {
			padding: 0 20px;
		}
 		h2 {
 			font-size: 70px;
 			margin-bottom: 15px;
 		}
 		p {
 			font-size: 14px;
 		}
	}
}


// home content
@media screen and (max-width: 1700px) {
	#institucionalHome {
		&:after {
			right: -200px;
		}
	}
}

@media screen and (max-width: 1320px) {
	#institucionalHome {
		&:after {
			right: -320px;
		}
	}
}

@media screen and (max-width: 1150px) {
	#institucionalHome {
		&:after {
			display: none;
		}
		h2 {
			text-align: center;
		}
		.content {
			margin: 0 auto;
			padding: 0;
		}
	}
}

@media screen and (max-width: 720px) {
	#institucionalHome {
		h2, h2 span {
			font-size: 80px;
		}
		.contatoBox {
			padding: 30px;
			a.linkPhone {
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 1700px) {
	
}