// Colors
$primaryLight: #ea433b;
$primary: #d42921;
$primaryDark: #bf1911;

$secondaryLight: #faf718;
$secondary: #eae600;
$secondaryDark: #d2ce00;

$branco: #fff;
$branco2: #f5f5f5;
$branco3: #f8f8f8;

$mutedGray: #ededed;
$lightGray: #e7e7e7;
$mediumGray: #c5c5c5;
$darkGray: #4c4c4c;
$baseGray: #898989;

// Sizes
$containerWidth: 1200px;
$containerBigWidth: 1680px;
$maxRowWidth: 2560px;

// media folders
$media_assets : "../../media/img/assets/";
$media_icons : "../../media/img/icons/";
$media_content : "../../media/img/content/";

// text
$text: #464646;

@import url('https://fonts.googleapis.com/css?family=Satisfy|Cookie|Montserrat:300,400,500,600');
$fontFamily__default: 'Montserrat', 'Roboto', 'Arial', 'Calibri', sans-serif;
$fwLight: 300; 
$fwRegular: 400; 
$fwMedium: 500; 
$fwSemiBold: 600;
$fontFamily__kodinah: 'Satisfy', cursive;


//@font-face {
//    font-family: 'Kodinah Diary';
//    src: url('../../media/type/KodinahDiaryRegular.woff2') format('woff2'),
//        url('../../media/type/KodinahDiaryRegular.woff') format('woff');
//    font-weight: normal;
//    font-style: normal;
//}
//$fontFamily__kodinah: 'Kodinah Diary', 'Monotype Cursive', script, sans-serif;

@mixin ff__kodinah() {
	font-family: $fontFamily__kodinah;
	font-weight: normal;
	font-style: normal;
}