// s categorias
@media screen and (max-width: 1170px) {
	.s-categoriasMenu {
		.categorias__grid {
			flex-wrap: wrap;
			padding: 0;
			.categorias__gridItem {
				flex: 1 1 33.3%;
				max-width: 33.3%;
				min-width: 33.3%;
				padding: 10px 5px;
			}
		}
	}
}
@media screen and (max-width: 620px) {
	.s-categoriasMenu {
		margin-top: -80px;
		.categorias__grid {
			.categorias__gridItem {
				flex: 1 1 50%;
				max-width: 50%;
				min-width: 50%;
			}
		}
	}
}

// footer
@media screen and (max-width: 940px) {
	.l-footer .footer_content {
		flex-wrap: wrap;
	}
	.l-footer .footer__brand {
		flex: 1 1 100%;
		min-width: 100%;
		order: 1;
		img {
			margin: 0 auto;
			display: block;
		}
	}
	.l-footer .footer__instagram {
		order: 2;
		padding: 40px 0;
		h3 {
			display: block;
			width: 100%;
			text-align: center;
			border-left: 0;
			padding: 0;
		}
		.instagramHero {
			padding-left: 0;
			padding-top: 10px;
			display: block;
			width: 100%;
			max-width: 600px;
			margin: 0 auto;
		}
	}
	.l-footer .footer__menu {
		order: 3;
		border-left: 0;
		padding: 10px 0 10px 0px;
		margin-left: 0;
		ul, li, a {
			text-align: center;
		}
	}
	.l-footer .menu__social {
		text-align: center;
		li {
			float: none;
			display: inline-block;
			margin-right: -4px;
			padding: 0 4px;
		}
	}
	
	.l-footer .copyright {
		display: block;
		span {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}
		.brandWendtone {
			margin: 0 auto;
		}
	}
}
@media screen and (max-width: 460px) {
	.l-footer .footer__instagram li {
		width: 50%;
		padding: 5px;
	}
	.l-footer .copyright {
		text-align: center;
	}
}