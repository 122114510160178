body {
	overflow-x: hidden;
}

.s-categoriasMenu {
	display: block;
	margin-top: -150px;
	position: relative;
	z-index: 2;
	.u-containerBig {
		padding: 0;
	}

	.categorias__grid {
		padding: 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.categorias__gridItem {
		padding: 5px;
		flex: 1 1 16.66%;
		max-width: 16.66%;	
		min-width: 16.66%;	
	}

	.card_hero, .card_box, .card_caption {
		width: 100%;
		display: block;
		position: relative;
		text-align: center;
	}

	.card_box {
		@include roundedAll(10px);
		@include transition();
		background: $primary;
		overflow: hidden;
		padding-top: 80%;
		position: relative;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
		img {
			@include transition();
			@include opacity(1);
			@include pb(0%);
			@include transforms(translateX(-50%), center);
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			height: 100%;
			width: auto;
			min-width: 100%;
			z-index: 1;
		}
		.cardName {
			@include opacity(0);
			@include transition();
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 100%;
			top: 50px;
			left: 0;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			.name {
				display: flex;
				flex-direction: row;
				align-items: center;
				flex: 1 1 100%;
				width: 100%;
				span {
					@include ff__kodinah();
					display: block;
					width: 100%;
					color: $branco;
					font-size: 44px;
					text-align: center;
				}
			}
			.fakeLink {
				display: block;
				width: 100%;
				flex: 1 1 54px;
				margin-top: auto;
				border-top: 1px solid rgba(255,255,255,0.3);
				height: 54px;
				line-height: 54px;
				text-transform: uppercase;
				font-weight: $fwSemiBold;
				color: $branco;
				text-align: center;
				font-size: 14px;
				&:hover {
					color: $secondary;
				}
			}
		}
	}

	.card_caption {
		text-transform: uppercase;
		font-size: 14px;
		font-weight: $fwSemiBold;
		margin-top: 10px;
		color: $text;
	}

	.card_hero {
		&.is-active,
		&:hover {
			.card_box {
				box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
				img {
					@include opacity(0.2);
					@include pb(100%);
				}
				.cardName {
					@include opacity(1);
					top: 0;
				}
			}
		}
	}
}

.s-carrinho_call {
	padding: 120px 0 220px 0;
	display: block;
	background: url(#{$media_assets}bg_carrinho_call.jpg);
	background-size: cover;
	position: relative;
	&:before {
		@include curve_after();
		background: url(#{$media_assets}curve-white--inverse.svg);
		bottom: inherit;
		top: 0;
		height: 96px;
	}
	&:after {
		@include curve_after();
		background: url(#{$media_assets}curve-dark.svg);
		height: 110px;
	}
	i {
		display: block;
		margin: 0 auto;
		svg {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	.carrinhoIcon {
		width: 87px;
		height: 76px;
	}
	.arrowIcon {
		width: 40px;
		height: 50px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	h3 {
		@include paragrafos();
		text-align: center;
		color: $branco;
		font-size: 26px;
		margin: 30px 0;
	}
	a {
		max-width: 410px;
		margin: 0 auto;
	}
}
.s-carrinho_call--withItens {
	.numItens {
		color: $secondary;
	}
	.c-btn {
		&:first-of-type {
			margin-bottom: 10px;
		}
	}
}

.l-footer {
	background: #f1e6be;
	display: block;
	padding: 0 0 20px 0;
	.footer_content {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}
	.footer__brand {
		flex: 1 1 130px;
		min-width: 130px;
	}
	.footer__menu {
		flex: 1 1 280px;
		min-width: 280px;
		margin-left: 40px;
		padding: 10px 0 10px 40px;
		border-left: 1px solid #d4c89e;
	}
	.menu__primary {
		display: block;
		ul, li, a {
			display: block;
			width: 100%;
		}
		a {
			@include paragrafos();
			font-weight: $fwMedium;
			@include opacity(0.75);
			color: $text;
			padding: 0;
			margin-bottom: 15px;
			&:last-of-type {
				margin-bottom: 15px;
			}
			&:hover {
				@include opacity(1);
			}
		}
	}
	.menu__social {
		display: block;
		width: 100%;
		overflow: hidden;
		margin-top: 25px;
		li {
			margin-right: 10px;
			display: block;
			float: left;
		}
		a {
			display: block;
			@include roundedAll(15px);
			height: 30px;
			line-height: 30px;
			width: 30px;
            svg path, svg polygon {
                fill: $text;
            }
			&:hover {
				color: $secondaryLight;
				svg path, svg polygon {
					fill: $primary;
				}
			}
		}
	}
	.footer__instagram {
		flex: 1 1 auto;
		h3 {
			@include paragrafos();
			font-weight: $fwSemiBold;
			padding: 10px 0 10px 40px;
			border-left: 1px solid #d4c89e;
			color: $text;
			a {
                font-weight: $fwSemiBold;
				color: $primary;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.instagramHero {
			padding-left: 40px;
		}
		ul {
			display: block;
			overflow: hidden;
			@include calcWidthForPositionNegative(5px);
		}
		li {
			display: block;
			float: left;
			width: 25%;
			padding: 0 5px;
			overflow: hidden;
			@include transition();
			a {
				background: $primary;
				overflow: hidden;
				@include roundedAll(10px);
			}
			a, img {
				display: block;
				width: 100%;
			}
			&:hover {
				a {
					@include opacity(0.5);
				}
			}
		}
	}
	.copyright {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		flex-direction: row;
		span {
			font-size: 10px;
			@include letterSpacing(50);
			color: $text;
			margin-top: 40px;
			@include opacity(0.85);
			font-weight: $fwSemiBold;
			a {
				color: $primary;
				font-weight: $fwSemiBold;
				@include letterSpacing(50);
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.brandWendtone {
			display: block;
			max-width: 100px;
			img {
				display: block;
				width: 100%;
			}
		}
	}
}