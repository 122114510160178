body {
	background: $branco2;
}

.l-headerHome {
	background: $primary;
	display: block;
	width: 100%;
	position: relative;
	z-index: 1;
	overflow: hidden;
	&:after {
		@include curve_after();
	}
	.swiper-slide {
		display: block;
		width: 100%;
		background-position: center center !important;
		background-size: cover;
		background-repeat: no-repeat;
		overflow: hidden;
	}
	.u-flexContainer {
		height: 920px;
		max-height: 100vh;
		min-height: 720px;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		max-width: 700px;
	}
	.head_description {
		padding-left: 50px;
		display: block;
		width: 100%;
	}

	h2 {
		@include headerHome_h2();
	}
	p {
		@include headerHome_description();
	}
	a {
		max-width: 200px;
		margin-top: 30px;
	}
	.swiper-pagination {
		position: absolute;
		left: 40px !important;
		top: 50%;
		bottom: inherit !important;
		right: inherit !important;
		width: 8px !important;
		height: auto !important;
		@include transforms(translateY(-50%), center);
		.swiper-pagination-bullet {
			width: 8px;
			height: 20px;
			background: $branco;
			@include roundedAll(4px);
			padding: 0;
			float: none;
			display: block;
			margin: 0 0 10px 0 !important;
			cursor: pointer;
			@include opacity(1);
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.swiper-pagination-bullet-active {
			height: 40px;
			background: $secondary;
		}
	}
}

#institucionalHome {
	display: block;
	padding: 60px 0;
	position: relative;
	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 680px;
		height: 100%;
		background: url(#{$media_content}home/cover1.png);
		background-position: center right;
		background-repeat: no-repeat;
		background-size: contain;
		right: -1px;
		top: 0;
		z-index: 1;
	}
	&.bg2{
		&:after{
			background-image: url(#{$media_content}home/cover2.png);
		}
	}
	&.bg3{
		&:after{
			background-image: url(#{$media_content}home/cover3.png);
		}
	}
	&.bg4{
		&:after{
			background-image: url(#{$media_content}home/cover4.png);
		}
	}
	&.bg5{
		&:after{
			background-image: url(#{$media_content}home/cover5.png);
		}
	}
	.u-container {
		z-index: 3;
	}
	h2, h2 span {
		@include ff__kodinah();
		font-size: 90px;
		line-height: 80px;
	}
	h2 span {
		color: $primary;
	}
	h2 {
		margin-bottom: 20px;
	}
	.content {
		padding-left: 70px;
		display: block;
		max-width: 780px;
	}
	p {
		@include paragrafos();
		strong {
			color: $primary;
		}
	}
	.contatoBox {
		@include roundedAll(10px);
		@include transition();
		border: 1px solid #d1d1d1;
		display: block;
		padding: 40px;
		position: relative;
		margin-top: 35px;
		&:before {
			content: '';
			display: block;
			height: 50px;
			width: 50px;
			background-color: #dddddd;
			background-image: url('../../media/img/icons/contato_phone.svg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 20px;
			position: absolute;
			top: -25px;
			left: -25px;
			@include roundedAll(25px);
		}
		p {
			@include opacity(0.75);
			@include transition();
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		a {
			color: $text;
			&:hover {
				color: $primary;
				text-decoration: underline;
			}
			&.linkPhone {
				font-size: 22px;
			}
			&.linkEmail {
				text-decoration: underline;
			}
		}
		&:hover {
			border: 1px solid $primary;
			p {
				@include opacity(1);
			}
		}
	}
}