.defaultNav {
	@include transition($ease: ease-in);
	display: block;
	width: 100%;
	max-width: $maxRowWidth;
	margin: 0 auto;
	padding: 40px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: transparent;
	z-index: 10;

	// Brand
	&__brand {
		display: block;
		float: left;
		position: relative;
		width: 130px;
		z-index: 1;
		a {
			overflow: hidden;
			display: block;
			img {
				display: block;
				width: 100%;
			}
		}
	}

	// Itens container
	&__contentNav {
		@include calc(width, 100%, '-', 130px);
		display: block;
		float: left;
		position: relative;
		z-index: 3;
		padding-left: 60px;
		vertical-align: middle;
		&:before {
			@include opacity(0.4);
			background: $branco;
			content: '';
			display: block;
			height: 30px;
			left: 30px;
			position: absolute;
			top: 20px;
			width: 2px;
		}
	}

	// main menu & social menu
	&__menu, &__social {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		li {
			display: block;
			float: left;
			position: relative;
			&:last-of-type {
				margin-right: 0;
			}
		}
		a {
			@include transition();
			display: block;
			height: 72px;
			line-height: 72px;
		}
	}
	
	// main menu
	&__menu {
		padding-right: 60px;
		&:after {
			@include opacity(0.4);
			background: $branco;
			content: '';
			display: block;
			height: 30px;
			right: 28px;
			position: absolute;
			top: 20px;
			width: 2px;
		}
		li {
			margin-right: 40px;
		}
		a {
			color: $branco;
			font-size: 16px;
			font-weight: $fwMedium;
			&:hover {
				color: $secondary;
			}
			&.is-active {
				color: $secondaryLight;
			}
		}
	}

	// social menu
	&__social {
		li {
			margin-right: 10px;
		}
		a {
			@include roundedAll(15px);
			height: 30px;
			line-height: 30px;
			width: 30px;
			svg{
				width: 100%;
				height: auto;
			}
			&:hover {
				color: $secondaryLight;
				svg path, svg polygon {
					fill: $secondaryLight;
				}
			}
		}
	}

	// mask menu
	&__maskMenu {
		@extend .u-hideElement;
		background: $primaryDark;
		bottom: 0;
		display: none;
		height: 100%;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 2;
		&.is-active {
			@extend .u-showElement;
			@include opacity(0.8);
		}
	}

	// modifiers
	&--scroll {
		background: $primary;
		top: 0px;
		padding: 10px 40px;
	}

	&--hide {
		top: -150px;
	}

	&--middle {
		padding: 5px 40px;
	}
}

// Responsive
@media screen and (max-width: 1100px){
	.defaultNav {
		&__contentNav {
			//@include scroll($bg: $primaryDark, $bar: $secondary, $size: 8px, $radius: 0px);
			@include transition();
			background: $primary;
			height: 100vh;
			overflow: auto;
			position: fixed;
			right: -300px;
			top: 0;
			width: 200px;
			padding-left: 0;
			max-width: 100%;
			padding-bottom: 50px;
			&:before, &:after {
				display: none;
			}
			&.is-active {
				right: 0;
			}
		}
		&__menu {
			width: 100%;
			padding: 20px 16px 0 16px;
			margin-top: 80px;
			border-top: 1px solid $primaryDark;
			border-bottom: 1px solid $primaryDark;
			&:before, &:after {
				display: none;
			}
			li {
				margin-right: 0px;
				margin-bottom: 20px;
				float: none;
				text-align: right;
			}
			a {
				height: 40px;
				line-height: 40px;
				color: $branco;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
		&__social {
			padding: 15px 15px 20px 15px;
		}
		&__maskMenu {
			display: block;
		}
	}

	.c-hamburguer {
		display: block;
	}
}

@media screen and (max-width: 420px) {
	.defaultNav {
		padding: 40px 15px;
	}
	.c-hamburguer {
		right: 80px;
	}
}